@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./prism.css";

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

code {
  counter-reset: line;
}

code > [data-line]::before {
  counter-increment: line;
  content: counter(line);

  display: inline-block;
  width: 1rem;
  margin-right: 2rem;
  text-align: right;
  color: gray;
}

code[data-line-numbers-max-digits="2"] > [data-line]::before {
  width: 2rem;
}

code[data-line-numbers-max-digits="3"] > [data-line]::before {
  width: 3rem;
}

.tightButton {
  padding: 0 !important;
  line-height: 1 !important;
  height: auto !important;
  min-height: 0 !important;
  min-width: 0 !important;
}

.bg-spiral-gradient {
  --light-color: #3498db; /* Define light mode color as blue */
  --dark-color: #f39c12; /* Define dark mode color as orange */
  background: radial-gradient(
    circle,
    rgba(var(--light-color), 0) 30%,
    rgba(var(--dark-color), 1) 90%
  );
  background-size: 300% 300%;
  animation: spiral-gradient-animation 10s linear infinite;
}

@keyframes spiral-gradient-animation {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 100%;
  }
}

@media (prefers-color-scheme: dark) {
  .dark .bg-spiral-gradient {
    --light-color: #f39c12; /* Set blue color for dark mode */
    --dark-color: #3498db; /* Set orange color for light mode */
  }
}

@media (prefers-color-scheme: light) {
  .light .bg-spiral-gradient {
    --light-color: #3498db; /* Set blue color for light mode */
    --dark-color: #f39c12; /* Set orange color for dark mode */
  }
}
